@import "src/scss/module";

.header {
  padding: 0;
  overflow-y: scroll;
  max-height: 100vh;
  border-bottom: 1px solid var(#{--bs-light});
  background-color: $white;

  @include media-breakpoint-up(md) {
    overflow-y: initial;
  }

  &__brand {
    padding: 0 !important;

    img {
      width: 214px;
      height: 83px;
      object-fit: contain;
    }
  }

  :global {
    .navbar {
      &-toggler {
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: 1.5em;
          height: 1.5em;

          span {
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
            height: 3px;
            width: 100%;
            background-color: #d9d9d9;
          }
        }
      }

      &-collapse.show {
        box-shadow: $box-shadow-sm;

        @include media-breakpoint-up(sm) {
          box-shadow: none;
        }
      }

      &-nav {
        min-height: 83px;

        & > .dropdown {
          & > .nav-item {
            height: 100%;

            @include media-breakpoint-up(sm) {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            &:focus,
            &:hover {
              background-color: rgba($black, 0.025);

              & > .nav-link {
                color: var(#{--bs-tertiary}) !important;
              }
            }

            & > .nav-link {
              //
              white-space: nowrap;
              padding: $spacer;
              color: var(#{--bs-dark}) !important;
            }

            & > .dropdown-toggle {
              display: flex;
              align-items: center;
              gap: $spacer * 0.5;
              justify-content: center;
              background-color: transparent !important;
              border: 0 !important;
              padding: $spacer $spacer $spacer 0;
              transition: none !important;

              &:after {
                display: none;
              }

              & > svg {
                fill: var(#{--bs-secondary}) !important;
              }
            }
          }

          & > .dropdown-menu {
            border: 1px solid $white;
            border-radius: 0;
            margin-top: 0;
            padding: 0;

            & > .nav-item {
              display: flex;
              align-items: center;
            }

            .nav-link {
              color: inherit;
              //
              padding: $spacer * 0.75 $spacer * 1.5;
              white-space: nowrap;
            }
          }
        }

        & > .nav-item {
          display: flex;
          align-items: center;

          & > .nav-link {
            //
            white-space: nowrap;
            padding: $spacer;
          }
        }
      }
    }
  }
}
